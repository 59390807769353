import React from "react"
import { Link } from "gatsby"

import "../styles/bulmaimport.scss"
import "../styles/styles.scss"
import "bulma-spacing"
import PostForm from "../components/postform"

const IndexPage = () => (
    <section class="hero is-info is-fullheight">
      <div class="hero-head">
      </div>
      <div class="hero-body">
        <div class="container has-margin-top-50">
          <h1 class="title is-1">
            Thanks for visiting Dojay Ventures
          </h1>
          <h2 class="subtitle is-3">
            We invest in innovative and impactful seed stage startups
          </h2>
          <div class="buttons">
            <PostForm />
            <a class="button is-info is-inverted is-outlined" href="mailto:jay@dojayventures.com">
              Email us
            </a>
          </div>
        </div>
      </div>
    </section>
)

export default IndexPage
