import React, { useEffect } from 'react';
import * as typeformEmbed from '@typeform/embed';

const PostForm = () => {
  useEffect(() => {
    const popupform = typeformEmbed.makePopup("https://jaypatel349724.typeform.com/to/BfNxP0", {
        mode: 'popup',
        autoClose: 3000,
        hideFooter: true,
        hideHeaders: true
    });
    document.getElementById('bt-popup').addEventListener('click', function() {
      popupform.open()
    });
  }, []);
  return (
    <button id="bt-popup" class="button is-info is-light">
      Tell us more about your startup
    </button>
  )
}

export default PostForm
